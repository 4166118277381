<template>
    <div class="team-intro">
        <div class="bg-overlay"></div>
        <div class="titles">
            <h2 class="title">Bestway Studio</h2>
            <p class="subtitle">
                Le damos concepto a tus ideas
            </p>
        </div>
    </div>
    <section id="portfolio">
        <div class="gd-info">
            <h3 class="title">Portafolio</h3>
            <p class="description">
                La fotografía es el arte que nos permite hacer tangible a los recuerdos.
            </p>
        </div>

        <div class="gd-portfolio">
            <div class="gd-gallery">
                <div class="studio-image-mini image-13" @click="showModal('image-13')"></div>
                <div class="studio-image-mini image-14" @click="showModal('image-14')"></div>
                <div class="studio-image-mini image-15" @click="showModal('image-15')"></div>
                <div class="studio-image-mini image-16" @click="showModal('image-16')"></div>
                <div class="studio-image-mini image-17" @click="showModal('image-17')"></div>
                <div class="studio-image-mini image-18" @click="showModal('image-18')"></div>
                <div class="studio-image-mini image-19" @click="showModal('image-19')"></div>
                <div class="studio-image-mini image-20" @click="showModal('image-20')"></div>
            </div>
        </div>
    </section>
    <section class="section" id="photo">
        <div class="section-img-container">
          <img src="../../assets/studio/photo-studio.png" alt="">
        </div>
        <div class="half-wide">
            <h3 class="title">Fotografía</h3>
            <p class="description">
                ¡Contamos la historia de tu marca! Fotografía gastronómica, producto, espacios, exteriores, estudio y más.
            </p>
        </div>
    </section>
    <section class="section reverse" id="audiovisuals">
        <div class="section-img-container">
          <img src="../../assets/studio/audiovisual.jpg" alt="">
        </div>
        <div class="half-wide">
            <h3 class="title">Audiovisual</h3>
            <p class="description">
                Los audiovisuales llenan de vida las marcas, además, ayudan a
                marcar la diferencia y brindan una mejor experiencia a los consumidores.
            </p>
        </div>
    </section>

    <!-- Modals -->
    <div id="image-13" class="modal" @click="closeModal('image-13')">
        <span class="close" id="modal-close" @click="closeModal('image-13')">&times;</span>
        <div class="modal-content image-13"></div>
    </div>

    <div id="image-14" class="modal" @click="closeModal('image-14')">
        <span class="close" id="modal-close" @click="closeModal('image-14')">&times;</span>
        <div class="modal-content image-14"></div>
    </div>

    <div id="image-15" class="modal" @click="closeModal('image-15')">
        <span class="close" id="modal-close" @click="closeModal('image-15')">&times;</span>
        <div class="modal-content image-15"></div>
    </div>

    <div id="image-16" class="modal" @click="closeModal('image-16')">
        <span class="close" id="modal-close" @click="closeModal('image-16')">&times;</span>
        <div class="modal-content image-16"></div>
    </div>

    <div id="image-17" class="modal" @click="closeModal('image-17')">
        <span class="close" id="modal-close" @click="closeModal('image-17')">&times;</span>
        <div class="modal-content image-17"></div>
    </div>

    <div id="image-18" class="modal" @click="closeModal('image-18')">
        <span class="close" id="modal-close" @click="closeModal('image-18')">&times;</span>
        <div class="modal-content image-18"></div>
    </div>

    <div id="image-19" class="modal" @click="closeModal('image-19')">
        <span class="close" id="modal-close" @click="closeModal('image-19')">&times;</span>
        <div class="modal-content image-19"></div>
    </div>

    <div id="image-20" class="modal" @click="closeModal('image-20')">
        <span class="close" id="modal-close" @click="closeModal('image-20')">&times;</span>
        <div class="modal-content image-20"></div>
    </div>

    <QuoteBlock/>
</template>

<script>
import QuoteBlock from '@/components/QuoteBlock.vue'

export default {
  name: 'SocialMedia',
  components: {
    QuoteBlock
  },
  methods: {
    showModal (id) {
      var modal = document.getElementById(id);
      modal.style.display = 'block';
    },
    closeModal (id) {
      var modal = document.getElementById(id);
      modal.style.display = 'none';
    }
  }
}
</script>

<style scoped>
    .section {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }
    .section-img-container{
        width: 50%;
        height: 100%;
        box-sizing: border-box;
    }

    .section-img-container img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .half-wide{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
    }
    .half-wide p, .half-wide h3 {
        margin: 0 40px;
    }
    .reverse {
        flex-direction: row-reverse;
    }
    #photo {
        text-align: left;
    }
    #audiovisuals {
        text-align: right;
    }
    .team-intro{
        background-image: url('../../assets/img/BANNER BWS LARGE.jpg');
    }
    .photo-portfolio{
        min-height: 65%;
    }
    .photo-gallery{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: center;
    }
    .gd-gallery{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        padding: 5%;
        height: 100%;
    }
    .gd-gallery a {
        margin: 5px 10px;
    }
    .gd-info{
        min-height: 25%;
        box-sizing: border-box;
        padding: 5% 2% 0 2%;
    }
    .title{
        font-size: 3rem;
        margin: 0;
    }
    .av-info{
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        justify-content: center;
        padding: 5%;
        box-sizing: border-box;
    }
    .description{
        opacity: 0.66;
    }
    .av-img-container, .av-info, .il-img-container, .il-info{
        width: 50%;
    }
    .av-img-container img, .il-img-container img {
        width: 100%;
        height: 100%;
    }
    .gd-gallery img {
        height: 200px;
        width: 230px;
    }

    .modal {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
    }

    .close {
        cursor: pointer;
        color: white;
        float: right;
        font-size: 28px;
        font-weight: bold;
        position: relative;
        top: 10px;
        right: 20px;
    }

    .modal-content {
        border-radius: 5px;
        background-color: #fefefe;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 auto;
        height: 100%;
        border: 1px solid #888;
        width: 80%;
    }

    .studio-image-mini {
        height: 250px;
        width: 250px;
        margin: 10px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer; 
    }

    .image-13 {
        background-image: url(../../assets/studio/13.jpg);
    }
    .image-14 {
        background-image: url(../../assets/studio/14.jpeg);
    }
    .image-15 {
        background-image: url(../../assets/studio/15.jpg);
    }
    .image-16 {
        background-image: url(../../assets/studio/16.jpeg);
    }
    .image-17  {
        background-image: url(../../assets/studio/17.jpeg);
    }
    .image-18  {
        background-image: url(../../assets/studio/18.jpeg);
    }
    .image-19  {
        background-image: url(../../assets/studio/19.jpeg);
    }
    .image-20  {
        background-image: url(../../assets/studio/20.jpeg);
    }

    @media screen and (max-width: 600px) {
        .modal-content {
            margin-top: 35%;
            height: 50%;
            width: 100%;
        }
        .section-img-container {
            width: 100%;
        }
        .half-wide {
            margin: 30px;
            width: 100%;
        }
        #photo {
            text-align: center;
            flex-direction: column-reverse;
            align-items: center;
        }
        #audiovisuals {
            text-align: center;
            flex-direction: column-reverse;
            align-items: center;
        }
        .gd-gallery {
            padding: 2%;
            margin-bottom: 10%;
        }
        .gd-gallery img {
            width: 260px;
        }
        .section {
            flex-direction: column;
        }
    }
</style>
